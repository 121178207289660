/* eslint-disable react/prop-types */
// importSource //

import React, { useContext } from "react";
import tw, { css } from "twin.macro";
import { useTheme } from "~hooks";
import { Button, Grid, KlaviyoForm } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

import leaves from "~assets/images/leaves.jpg";

const Newsletter = () => {
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  //

  return (
    <section
      css={[
        css`
          ${colourTransitionCSS};

          background: ${lowImpactMode
            ? colours.background
            : colours.foreground};
          color: ${lowImpactMode ? colours.foreground : colours.background};
        `,
        tw`w-full relative`
      ]}
    >
      <Grid>
        <div
          css={[
            tw`col-span-12 md:col-span-5 relative flex items-center justify-center pt-8 md:pt-0 pb-8 md:pb-0`
          ]}
        >
          <h2 css={[tw`font-body text-h3 md:text-h2-md`]}>
            We can <em>grow together</em> for a world you want to live{` `}
            <em>and work</em> in.
          </h2>
        </div>

        <div
          css={[
            css`
              padding: 3rem 0 4rem;

              ${MEDIA_QUERIES.desktop} {
                padding: calc((100vw - 6rem) / 12);
              }
            `,
            tw`col-span-12 md:col-span-6 md:col-start-7 relative`
          ]}
        >
          <figure
            css={[
              css`
                width: calc(100% + 3rem);
                margin-left: -1.5rem;

                background: ${lowImpactMode
                  ? colours.foreground
                  : colours.background};

                img {
                  transition: 0.3s ease opacity;

                  opacity: ${lowImpactMode ? 0 : 1};
                }

                ${MEDIA_QUERIES.desktop} {
                  width: calc(100% + 3rem);

                  margin-left: 0;
                }
              `,
              tw`h-full absolute top-0 bottom-0 left-0 flex items-center justify-center`
            ]}
          >
            <img
              css={[tw`w-full h-full relative block object-cover`]}
              src={leaves}
              alt="Leaves and foliage"
            />
          </figure>

          {/* // */}

          <div css={[tw`relative z-10`]}>
            <KlaviyoForm
              listId="XkpcJQ"
              render={({ data, status, submit, update }) => {
                const { submitting, submitted } = status;

                let buttonText = `Join Us`;
                let formOpacity = 1;

                if (submitting) {
                  buttonText = `Working...`;
                  formOpacity = 0.75;
                }

                if (submitted) {
                  formOpacity = 0;
                }

                //

                return (
                  <>
                    <div
                      css={[
                        tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center pointer-events-none`
                      ]}
                    >
                      <div
                        css={[
                          css`
                            transition: 1s ease opacity, 1s ease transform;
                            transition-delay: 0.5s;

                            opacity: ${submitted ? 1 : 0};
                            transform: translate3d(
                              0,
                              ${submitted ? `0` : `0.5rem`},
                              0
                            );

                            padding: 1rem 1rem;

                            background: ${lowImpactMode
                              ? colours.background
                              : colours.foreground};
                            color: ${lowImpactMode
                              ? colours.foreground
                              : colours.background};

                            ${MEDIA_QUERIES.desktop} {
                              padding: 5%;
                            }
                          `,
                          tw`w-full relative block rounded-xl`
                        ]}
                      >
                        <p
                          css={[
                            tw`w-full relative block text-b1 md:text-b1-md font-body`
                          ]}
                        >
                          <em>Thank you for subscribing</em>, we’ll be in touch
                          with you shortly.
                        </p>
                      </div>
                    </div>

                    <div
                      css={[
                        css`
                          transition: 1s ease opacity, 1s ease transform;

                          opacity: ${submitted ? 0 : 1};
                          transform: translate3d(
                            0,
                            ${submitted ? `0` : `0.5rem`},
                            0
                          );

                          padding: 1rem 1rem;

                          background: ${lowImpactMode
                            ? colours.background
                            : colours.foreground};
                          color: ${lowImpactMode
                            ? colours.foreground
                            : colours.background};

                          ${MEDIA_QUERIES.desktop} {
                            padding: 5%;
                          }
                        `,
                        tw`w-full relative block rounded-xl`
                      ]}
                    >
                      <p
                        css={[
                          tw`w-full md:w-2/3 relative block mb-6 md:mb-8 text-b1 md:text-b1-md font-body`
                        ]}
                      >
                        Stay informed of positive developments at{` `}
                        <em>The WIP</em>.
                      </p>

                      <label
                        css={[
                          css`
                            transition: 0.5s ease opacity;

                            opacity: ${formOpacity};

                            margin-bottom: 1rem;

                            ${MEDIA_QUERIES.desktop} {
                              margin-bottom: 6%;
                            }
                          `,
                          tw`relative block`
                        ]}
                        htmlFor="first_name"
                      >
                        <p
                          css={[
                            css`
                              margin-bottom: 0.5rem;

                              ${MEDIA_QUERIES.desktop} {
                                margin-bottom: 2%;
                              }
                            `,
                            tw`text-b2 md:text-b2-md font-body`
                          ]}
                        >
                          <em>Preferred name</em>
                        </p>

                        <input
                          id="k_id_first_name"
                          name="first_name"
                          css={[
                            css`
                              padding: 0.5rem 0.75rem 0.4rem;

                              ${MEDIA_QUERIES.desktop} {
                                padding: 3% 3% 2%;
                                pointer-events: auto !important;
                              }
                            `,
                            tw`w-full relative block overflow-hidden bg-white rounded-lg font-body text-b2 md:text-b2-md`
                          ]}
                          onChange={(e) => {
                            update({
                              ...data,
                              first_name: e.currentTarget.value
                            });
                          }}
                          placeholder="Please enter your preferred name*"
                          readOnly={submitting || submitted}
                          required
                          type="text"
                        />
                      </label>

                      <label
                        css={[
                          css`
                            transition: 0.5s ease opacity;

                            opacity: ${formOpacity};

                            margin-bottom: 1rem;

                            ${MEDIA_QUERIES.desktop} {
                              margin-bottom: 6%;
                            }
                          `,
                          tw`relative block`
                        ]}
                        htmlFor="last_name"
                      >
                        <p
                          css={[
                            css`
                              margin-bottom: 0.5rem;

                              ${MEDIA_QUERIES.desktop} {
                                margin-bottom: 2%;
                              }
                            `,
                            tw`text-b2 md:text-b2-md font-body`
                          ]}
                        >
                          <em>Surname</em>
                        </p>

                        <input
                          id="k_id_last_name"
                          name="last_name"
                          css={[
                            css`
                              padding: 0.5rem 0.75rem 0.4rem;

                              ${MEDIA_QUERIES.desktop} {
                                padding: 3% 3% 2%;
                                pointer-events: auto !important;
                              }
                            `,
                            tw`w-full relative block overflow-hidden bg-white rounded-lg font-body text-b2 md:text-b2-md`
                          ]}
                          onChange={(e) => {
                            update({
                              ...data,
                              last_name: e.currentTarget.value
                            });
                          }}
                          placeholder="Please enter your surname*"
                          readOnly={submitting || submitted}
                          required
                          type="text"
                        />
                      </label>

                      <label
                        css={[
                          css`
                            transition: 0.5s ease opacity;

                            opacity: ${formOpacity};

                            margin-bottom: 1rem;

                            ${MEDIA_QUERIES.desktop} {
                              margin-bottom: 6%;
                            }
                          `,
                          tw`relative block`
                        ]}
                        htmlFor="email"
                      >
                        <p
                          css={[
                            css`
                              margin-bottom: 0.5rem;

                              ${MEDIA_QUERIES.desktop} {
                                margin-bottom: 2%;
                              }
                            `,
                            tw`text-b2 md:text-b2-md font-body`
                          ]}
                        >
                          <em>Email</em>
                        </p>

                        <input
                          id="newsletter_email"
                          name="email"
                          css={[
                            css`
                              padding: 0.5rem 0.75rem 0.4rem;

                              ${MEDIA_QUERIES.desktop} {
                                padding: 3% 3% 2%;
                                pointer-events: auto !important;
                              }
                            `,
                            tw`w-full relative block overflow-hidden bg-white rounded-lg font-body text-b2 md:text-b2-md`
                          ]}
                          onChange={(e) => {
                            update({
                              ...data,
                              email: e.currentTarget.value
                            });
                          }}
                          placeholder="Please enter your email*"
                          readOnly={submitting || submitted}
                          required
                          type="email"
                        />
                      </label>

                      <Button
                        _css={[
                          css`
                            transition: 0.5s ease opacity;

                            opacity: ${formOpacity};

                            height: 3rem;
                            overflow: hidden;
                            pointer-events: ${submitting || submitted
                              ? `none`
                              : `auto`} !important;

                            width: 6rem !important;
                            pointer-events: ${submitting || submitted
                              ? `none`
                              : `auto`};

                            ${MEDIA_QUERIES.desktop} {
                              width: 25% !important;
                              height: auto;
                            }
                          `
                        ]}
                        color="green-dark"
                        onClick={submit}
                        text={buttonText}
                      />
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      </Grid>
    </section>
  );
};

export default Newsletter;
